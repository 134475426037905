import {getTicketByWeb} from "@/api/OpenApi";
import {iLabLoginByTicket, startInWeb, startInWebQueue} from "../api/OpenApi";
import store from "../store";
import {UserModel} from "./UserModel";
import {msg_confirm} from "../utils/ele_component";

class OpenModel {
    // 网页获取ticket
    static async getTicketByWeb(appId) {
        let [res] = await getTicketByWeb({
            appId
        })
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

    // 网页内开始实训前排队检测
    static async startInWebQueue(appId) {
        let [res] = await startInWebQueue({
            appId
        })
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

    // 网页内开始实训
    static async startInWeb(appId) {
        let [res] = await startInWeb({
            appId
        })
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

    // iLab-通过ticket登录
    static async iLabLoginByTicket(ticket, courseId) {
        let [res] = await iLabLoginByTicket({
            ticket,
            courseId
        })
        if (res.code === "000000") {
            let result = res.data
            // 登录用户
            UserModel.setToken(result["token"])
            // 保存用户信息到store中
            let userInfo = result["userInfo"]
            await store.dispatch("setUserInfo", userInfo)
            await store.dispatch("setLoginFrom", "iLab")
            // 保存实训空间信息到session
            sessionStorage.setItem("iLabInfo", JSON.stringify(result["iLabInfo"]))
            // 保存登录方式
            return res.data
        } else {
            return false
        }
    }
}

export {OpenModel}
