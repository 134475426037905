import {getCourseRealTimeValue} from "../api/StatisticApi";

/**
 * 统计model
 */
class StatisticModel {
    // 获取某个课程的实时统计数据
    static async getCourseRealTimeValue(courseId) {
        let query = {
            courseId
        }
        let [res] = await getCourseRealTimeValue(query)
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

}

export {StatisticModel}
