<template>
  <div class="page-experimentInfo">
    <div class="header-container">
      <div class="header-box content-container flex flex-around">
        <div class="left-box">
          <div>
            <img class="avatar"
                 :src="course.avatarUrl"
                 alt="">
          </div>
          <div class="video-box flex flex-center">
            <el-button size="small" @click="playVideo(1)">实训简介视频</el-button>
            <el-button size="small" @click="playVideo(2)">教学引导视频</el-button>
          </div>
        </div>
        <div class="right-box">
          <div class="name">{{ course.name }}</div>
          <div class="des-text" v-for="item in course.shortDesText.split('\n')">
            <div class="text">{{ item }}</div>
          </div>
          <div class="button-box">
            <el-button type="primary" @click="clickStartExperimentForExpertBtn" size="mini" :loading="startDialog.loading">开始实训</el-button>
<!--            <el-button type="primary" @click="clickStartExperimentBtn" size="mini" :loading="startDialog.loading">开始实训</el-button>-->
<!--            <el-button v-if="!userInfo.userId" type="success"-->
<!--                       @click="clickStartExperimentForExpertBtn">-->
<!--              专家请点此直接开始实训-->
<!--            </el-button>-->
          </div>
        </div>
      </div>
    </div>
    <div class="info-container content-container flex flex-between">
      <el-tabs v-model="desTabShowName" type="border-card" class="left-container">
        <el-tab-pane :label="item.name" :name="item.name" v-for="item in course.tabs" v-if="item.showNav===true">
          <div class="html-view" v-html="item.text"></div>
<!--          <div class="pdf-container" v-if="item.name==='申报材料'">-->
<!--            <pdf v-for="i in pdfNumbers" :key="i" :src="course.pdfUrl" :page="i"></pdf>-->
<!--          </div>-->
        </el-tab-pane>
      </el-tabs>
      <div class="right-container">
        <el-card class="statistics-info right-li">
          <div class="title flex flex-between">
            <span>实训统计</span>
            <span class="remark">更新时间 {{ statisticRealTime.info.updateTime | dateFormat }}</span>
          </div>
          <el-divider></el-divider>
          <div class="number-info">
            <div class="li flex flex-between">
              <span>
                实训浏览量
                <el-tooltip class="item" effect="light" content="用户进入实训的次数" placement="top-start">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </span>
              <span>{{ statisticRealTime.info.viewNumber }}</span>
            </div>
            <div class="li flex flex-between">
              <span>
                实训人次
                 <el-tooltip class="item" effect="light" content="“实训人次”是指用户参与并完成实训的总次数,同一个用户可以做多次实训。"
                             placement="top-start">
                   <i class="el-icon-warning-outline"></i>
                 </el-tooltip>
              </span>
              <span>{{ statisticRealTime.info.scoreNumber }}</span>
            </div>
            <div class="li flex flex-between">
              <span>
                实训人数
               <el-tooltip class="item" effect="light" content="“实训人数”是指参与实训的用户总人数。同一个用户多次实训，实训人数只统计为1人。"
                           placement="top-start">
                 <i class="el-icon-warning-outline"></i>
               </el-tooltip>
              </span>
              <span>{{ statisticRealTime.info.userNumber }}</span>
            </div>
            <div class="li flex flex-between">
              <span>实训平均用时</span>
              <span>{{ statisticRealTime.info.averageUsedTime | scoreUseTimeFilter }}</span>
            </div>
            <div class="li flex flex-between">
              <span>
                实训完成率
                <el-tooltip class="item" effect="light" content="“实训完成率”是指完成实训的次数占参与实训总次数的百分比。"
                            placement="top-start">
                 <i class="el-icon-warning-outline"></i>
               </el-tooltip>
              </span>
              <span>{{ (statisticRealTime.info.completePoint * 100).toFixed(2) }}%</span>
            </div>
            <div class="li flex flex-between">
              <span>
                实训通过率
                 <el-tooltip class="item" effect="light" content="“实训通过率”是指实训成绩60分及以上的次数占实训总次数的百分比。"
                             placement="top-start">
                 <i class="el-icon-warning-outline"></i>
               </el-tooltip>
              </span>
              <span>{{ (statisticRealTime.info.passPoint * 100).toFixed(2) }}%</span>
            </div>
          </div>
          <div class="chart-info flex flex-between">
            <div class="number-box">
              <el-tooltip class="item" effect="light" content="成绩≥85"
                          placement="top-start">
                <div class="li flex flex-start">
                  <i></i>
                  <span>优秀</span>
                  <span>{{ statisticRealTime.info.excellentNumber }}人次</span>
                </div>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="60≤成绩<85"
                          placement="top-start">
                <div class="li flex flex-start">
                  <i></i>
                  <span>达标</span>
                  <span>{{ statisticRealTime.info.standardNumber }}人次</span>
                </div>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="成绩<60"
                          placement="top-start">
                <div class="li flex flex-start">
                  <i></i>
                  <span>不达标</span>
                  <span>{{ statisticRealTime.info.failNumber }}人次</span>
                </div>
              </el-tooltip>
            </div>
            <div id="statistic-user-number" style="height: 120px;width: 120px;margin-top: 15px;"></div>
          </div>
        </el-card>
      </div>
    </div>
    <!--开始实训弹窗-->
    <el-dialog
        title=""
        :visible.sync="startDialog.dialog"
        width="600px"
        center
        @close="clickCloseStartExperimentDialog"
        :close-on-click-modal="false"
        v-el-drag-dialog>
      <div class="dialog-container start-dialog">
        <div class="tips-box">
          <div class="speed-box">
            <el-divider>带 宽 测 试</el-divider>
            <div class="text flex flex-center">为了更好的使用体验，请确认您的带宽是否超过100Mbps。
              <el-button type="default" size="small" @click="window.open('https://www.speedtest.cn/')">带宽测试</el-button>
            </div>
          </div>
          <div class="line-box">
            <el-divider>访 问 排 队</el-divider>

            <div class="text flex flex-center">正在进入实训人数: {{ this.queueInfo.doingNumber }}</div>
            <div class="text flex flex-center">
              等待进入实训人数: {{ this.queueInfo.waitTotalNumber ? this.queueInfo.waitTotalNumber : 0 }}
            </div>
            <div class="text flex flex-center" v-if="this.queueInfo.waitSerialNumber===-1">
              等待进入实训人数已超额，请稍等。
            </div>
            <div class="text flex flex-center" v-if="this.queueInfo.waitTotalNumber>0">
              <span style="font-weight: bold">正在排队中，请不要关闭此窗口,关闭将导致排队失效！</span>
            </div>
            <div v-if="this.queueInfo.waitSerialNumber>0" class="text flex flex-center" style="font-weight: bold">
              您排在第<span style="padding:0px 5px;"> {{ this.queueInfo.waitSerialNumber }} </span>位，请稍等。
              <i class="el-icon-loading"></i>
            </div>
          </div>
        </div>
        <!--网页版-->
        <div v-if="course.courseType==='网页版'&&this.queueInfo.waitSerialNumber===0">
          <el-divider>请 点 击 下 面 链 接 进 入 实 训</el-divider>
          <div class="url" style="text-align: center">
            <a :href="startDialog.jumpUrl" target="_blank">{{
                startDialog.jumpUrl
              }}</a>
          </div>
        </div>
        <div v-if="course.courseType==='客户端版'&&this.queueInfo.waitSerialNumber===0">
          <el-divider>请 点 击 下 载 实 训 资 源 包</el-divider>
          <div class="url" style="text-align: center">
            <a :href="course.clientText" target="_blank">{{
                course.clientText
              }}</a>
          </div>
          <div class="html-view limit-height" v-html="course.clientDesText" style="margin-top: 10px"></div>
        </div>
      </div>
    </el-dialog>
    <!--视频播放弹窗-->
    <el-dialog
        :title="videoPlay.title"
        :visible.sync="videoPlay.dialog"
        :close-on-click-modal="true"
        @close="stopVideo()"
        width="1200px"
        center
    >
      <div class="dialog-container dialog-video">
        <div id="mui-player"></div>
      </div>
    </el-dialog>
    <!--实训空间用户弹窗-->
    <el-dialog
        title="欢迎访问本实训"
        :visible.sync="iLabUser.dialog"
        :close-on-click-modal="true"
        width="500px"
        center
    >
      <div class="dialog-container dialog-iLab">
        <el-descriptions column="1" border>
          <el-descriptions-item label="来源：">国家虚拟仿真实训教学课程共享平台</el-descriptions-item>
          <el-descriptions-item label="账号：">{{ userInfo.account }}</el-descriptions-item>
          <el-descriptions-item label="姓名：">{{ userInfo.name }}</el-descriptions-item>
        </el-descriptions>
        <div class="flex flex-center" style="margin-top: 30px">
          <el-button type="primary" @click="iLabUser.dialog=false">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getQuery} from "../utils/common";
import {CourseModel} from "../model/CourseModel";
import {msg_confirm, msg_confirm_choose, msg_err} from "../utils/ele_component";
import {dateFormat, scoreUseTimeFilter} from "../filters";
import elDragDialog from "@/directive/el-drag-dialog";
import * as echarts from 'echarts';
import {OpenModel} from "../model/OpenModel";
import {StatisticModel} from "../model/StatisticModel";
import {mapState} from "vuex";
import store from "../store";
import MuiPlayer from 'mui-player'
import 'mui-player/dist/mui-player.min.css'
import {UserModel} from "../model/UserModel";

export default {
  name: "CourseInfo",
  directives: {
    elDragDialog
  },
  filters: {
    dateFormat: dateFormat,
    scoreUseTimeFilter,
  },
  components: {},
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
    })
  },
  data() {
    return {
      window: window,
      courseId: "",
      coursePath: "",
      desTabShowName: "",
      course: {
        courseSubjectEntity: [{}],
        collegeEntity: [{}],
        shortDesText: "",
        tabs: [{}],
        headerTabs: []
      },
      statisticRealTime: {
        info: {}
      },
      // 开始实训跳转和介绍弹窗
      startDialog: {
        loading:false,
        dialog: false,
        clientText: ""
      },
      // 视频播放弹窗
      videoPlay: {
        title: "",
        dialog: false,
        player: {},
        url: ""
      },
      // 标记登录方式
      loginType: "us",// us我们平台 iLab实训空间 sczj四川职教平台
      iLabUser: {
        dialog: false,
      },
      // pdf
      pdfNumbers: 0,
      queueInfo: {
        doingNumber: 0,// 正在进入实训的人数
        waitSerialNumber: 0,// 排队序号 -1排队人数已超过
        waitTotalNumber: 0,// 排队的总人数
      },
      queueInterval: undefined
    }
  },
  async mounted() {
    this.coursePath = this.$route.params["coursePath"]
    // 获取课程详情
    this.course = await CourseModel.getOneByPath(this.coursePath).catch(res => {
      msg_err("获取课程详情失败！链接地址不正确")
    })
    if (!this.course) {
      return
    }
    this.courseId = this.course.courseId;
    if (!this.courseId) {
      msg_err("链接地址不正确!");
      window.location.href = "/courseList"
      return
    }
    // 保存课程id到store
    await store.dispatch("setCourseId", this.courseId)
    // 保存课程id到session
    sessionStorage.setItem("courseId", this.courseId)
    // 保存课程path到session
    sessionStorage.setItem("coursePath", this.coursePath)

    // 保存课程信息到store
    await store.dispatch("setCourseInfo", this.course)
    // 保存课程信息到session
    sessionStorage.setItem("courseInfo", JSON.stringify(this.course))
    // 判断是否已浏览
    if (!sessionStorage.getItem("viewCourse_" + this.courseId)) {
      sessionStorage.setItem("viewCourse_" + this.courseId, this.courseId)
      // todo 增加网页浏览次数
    }
    this.getCourseInfo();
    this.loginCheck();
  },
  beforeDestroy() {
    clearInterval(this.queueInterval)
  },
  methods: {
    getPdfNumPages() {
      if (this.course.pdfUrl) {
        let loadingTask = pdf.createLoadingTask(this.course.pdfUrl)
        // 网上查询 都没有加promise执行的整页渲染 emmmm... 不知道他们怎么运行的
        loadingTask.promise.then(pdf => {
          // this.laoclUrl = loadingTask
          this.pdfNumbers = pdf.numPages
        }).catch((err) => {
          console.error('pdf加载失败')
        })
      }
    },
    // 登录方式判断
    async loginCheck() {
      let ticket = getQuery("ticket")
      // 实训空间适配
      if (ticket) {
        this.loginType = "iLab"

      }
      if (this.loginType === "iLab") {
        if (!sessionStorage.getItem("iLabInfo")) {
          // 获取实训空间accessToken
          let loginResult = await OpenModel.iLabLoginByTicket(ticket, this.courseId)
          this.iLabUser.dialog = true
        }
      }
    },
    // 生成网页链接
    generateWebUrl(result) {
      this.startDialog.jumpUrl = this.course.webUrl + `?accessToken=${result['access_token']}&username=${result['user_info']['username']}&userDisName=${result['user_info']['realName']}`
    },
    // 点击开始实训按钮
    async clickStartExperimentBtn() {
      this.startDialog.loading=true
      // 判断是否已登录
      if (!this.userInfo.hasOwnProperty("userId")) {
        msg_confirm_choose("您尚未登录，请先登录本平台。或从职业教育智慧教育平台、实验空间等外部平台进入！", "请先登录", "取消", "去登录").then(res => {
          if (res === "right") {
            this.$router.push("/login")
          }
        })
        return
      }
      if (!this.course.webUrl && this.course.courseType === "网页版") {
        await msg_confirm("实训项目部署中，请稍后!")
        this.startDialog.loading=false
        return
      }
      // 排队信息
      let queueInfo = await OpenModel.startInWebQueue(this.course.appId);
      this.queueInfo = queueInfo
      if (queueInfo.waitSerialNumber === 0) {// 没有排队人数，开始实训
        if (this.queueInterval) {
          clearInterval(this.queueInterval)
        }
        // 获取ticket
        let loginResult = await OpenModel.startInWeb(this.course.appId)
        if (loginResult) {
          clearInterval(this.queueInterval)
          this.generateWebUrl(loginResult);
        } else {
          msg_err("获取ticket失败，请联系管理员！")
          this.startDialog.loading=false
        }
      } else {// 需要排队，启动定时循环，5秒一次
        this.queueInterval = setInterval(this.updateQueue, 5000)
      }
      this.startDialog.dialog = true
      this.startDialog.loading=false
    },
    // 更新排队信息
    async updateQueue() {
      let queueInfo = await OpenModel.startInWebQueue(this.course.appId);
      this.queueInfo = queueInfo
      if (queueInfo.waitSerialNumber === 0) {// 没有排队人数，开始实训
        if (this.queueInterval) {
          clearInterval(this.queueInterval)
        }
        // 获取ticket
        let loginResult = await OpenModel.startInWeb(this.course.appId)
        if (loginResult) {
          this.generateWebUrl(loginResult);
        } else {
          msg_err("获取ticket失败，请联系管理员！")
        }
      }
    },
    // 点击关闭开始实训弹窗
    clickCloseStartExperimentDialog() {
      if (this.queueInfo.waitSerialNumber > 0) {
        msg_err("已取消排队!")
        clearInterval(this.queueInterval);
      }
    },
    // 网页版-点击专家开始实训按钮
    async clickStartExperimentForExpertBtn() {
      if (this.course.courseType === "网页版") {
        await UserModel.loginInPlatformForExpert()
        this.clickStartExperimentBtn();
      }
      if (this.course.courseType === "客户端版") {
        await UserModel.loginInPlatformForExpert()
        this.clickStartExperimentBtn();
      }
    },
    // 获取课程详情
    async getCourseInfo() {
      this.desTabShowName = this.course.tabs[0]["name"];
      document.title = this.course.name;
      // this.getPdfNumPages();
      (async () => {
        // 获取课程实时统计数据
        this.statisticRealTime.info = await StatisticModel.getCourseRealTimeValue(this.courseId)
        setTimeout(() => {
          // 绘制统计图
          // 基于准备好的dom，初始化echarts实例
          let myChart = echarts.init(document.getElementById('statistic-user-number'));
          // 绘制图表
          myChart.setOption({
            series: [
              {
                type: 'pie',
                label: {
                  normal: {
                    show: false
                  }
                },
                color: ["#dd5303", "#46b4e9", "#762983"],
                data: [
                  {value: this.statisticRealTime.info.excellentNumber},
                  {value: this.statisticRealTime.info.standardNumber},
                  {value: this.statisticRealTime.info.failNumber},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          });
        }, 1000)
      })().then();
    },
    // 播放视频
    playVideo(type) {
      let url = ""
      if (type === 1) {
        this.videoPlay.title = "实训简介视频"
        url = this.course.video1Url;
      }
      if (type === 2) {
        this.videoPlay.title = "教学引导视频"
        url = this.course.video2Url;
      }
      this.videoPlay.dialog = true
      setTimeout(() => {
        this.videoPlay.player = new MuiPlayer({
          container: '#mui-player',
          autoplay: true,
          pageHead: false,
          autoFit: true,
          src: url,
        })
      }, 500)
    },
    // 停止播放视频
    stopVideo() {
      this.videoPlay.player.destroy()
    }
  }
}
</script>

<style scoped lang="less">
@import '../style/app.less';

.page-experimentInfo {
  margin-bottom: 20px;
}

.header-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #2f3a59;

  .header-box {
    color: #fff;
    padding: 40px 0px;
    align-items: start;

    .right-box {
      width: 700px;

      .name {
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.5;
        margin-bottom: 10px;
      }

      .des-info {
        margin-bottom: 10px;

        span {
          font-size: 14px;
          color: #fff;
          opacity: .5;
          margin-right: 20px;
          line-height: 20px;
        }
      }

      .des-text {
        font-size: 14px;
        line-height: 22px;

        .text { // 简介分段
          text-indent: 2em;
        }
      }

      .button-box {
        margin-top: 30px;
        text-align: center;

        .el-button {
          padding: 12px 40px;
        }
      }
    }

    .left-box {
      padding-top: 5px;

      img.avatar {
        width: 350px;
        height: 210px;
      }

      .video-box {
        margin-top: 10px;
      }
    }
  }
}

.info-container {
  margin-top: 20px;
  position: relative;
  align-items: start;

  .left-container {
    width: 900px;
  }

  .right-container {
    width: 280px;
    color: #333;

    .right-li {
      margin-bottom: 20px;

      div.title {
        font-weight: 500;
        font-size: 15px;
        border-left: 4px solid @main-color;
        padding-left: 10px;
      }

      .el-divider--horizontal {
        margin: 8px 0px;
      }
    }

    .statistics-info {
      .title {
        .remark {
          font-size: 12px;
          color: #999;
        }
      }

      .number-info {
        .li {
          color: #fff;
          font-size: 13px;
          padding: 6px 20px;
          border-radius: 5px;
          margin-bottom: 5px;
        }

        .li:nth-child(1) {
          background-color: #d97408;
        }

        .li:nth-child(2) {
          background-color: #a621c2;
        }

        .li:nth-child(3) {
          background-color: #4976e8;
        }

        .li:nth-child(4) {
          background-color: #21a6c2;
        }

        .li:nth-child(5) {
          background-color: #eba705;
        }

        .li:nth-child(6) {
          background-color: #339900;
        }
      }

      .chart-info {
        padding-left: 5px;

        .li {
          color: #8b8b8b;
          font-size: 12px;
          margin-top: 15px;

          i {
            width: 15px;
            height: 15px;
            border-radius: 4px;
            margin-right: 5px;
          }

          span {
          }

          span:last-child {
            margin-left: 5px;
          }
        }

        .li:nth-child(1) i {
          background-color: #dd5303;
        }

        .li:nth-child(2) i {
          background-color: #46b4e9;
        }

        .li:nth-child(3) i {
          background-color: #762983;
        }
      }
    }

    .task-info {
      .li {
        .name {
          font-size: 14px;
          color: #333;
          white-space: nowrap;
          word-break: keep-all;
          text-overflow: ellipsis;
          margin-bottom: 5px;
          overflow: hidden;
          display: block;
          cursor: pointer;
        }

        .college {
          text-align: right;
          color: #999;
          font-size: 13px;
          white-space: nowrap;
          word-break: keep-all;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .button {
        margin-top: 20px;
        text-align: center;
      }
    }

    .comment-info {
      .title {
        span.number {
          font-size: 12px;
          color: #999;
        }
      }

      .comment-list {
        .li {
          color: #888;

          .comment-text {
            font-size: 13px;
            color: #555;
            line-height: 19px;
            margin-bottom: 10px;

            .text {
              margin-bottom: 3px;
            }
          }

          .user {
            font-size: 12px;

            .name {
              margin-right: 15px;
            }

            .date {

            }
          }
        }

        .button {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }

}

.start-dialog {
  .el-divider__text {
    font-size: 16px;
    font-weight: bold;
    color: #555;
  }

  .tips-box {
    margin-bottom: 50px;

    .el-divider--horizontal {
      margin: 10px 0px;
    }

    div.text {
      margin-top: 20px;
      margin-bottom: 10px;
      color: #666;
    }

    .speed-box {
      margin-bottom: 20px;
    }

    .line-box {

    }
  }
}
</style>
