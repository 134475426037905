import {request_async} from "@/utils/requestAsync";
import {API_URL} from "@/config/main";

// 获取ticket
export async function getTicketByWeb(data) {
    return request_async(API_URL + "/v1/open/ticketByWeb", "get", data);
}

// iLab通过ticket登录
export async function iLabLoginByTicket(data) {
    return request_async(API_URL + "/v1/open/iLab_loginByTicket", "get", data)
}

// 开始实训前排队检测
export async function startInWebQueue(data) {
    return request_async(API_URL + "/v1/open/startInWebQueue", "get", data);
}

// 开始实训
export async function startInWeb(data) {
    return request_async(API_URL + "/v1/open/startInWeb", "get", data);
}
